<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thống kê lượt phục vụ'">
          <template v-slot:toolbar>
            <div class="row">
              <div class="col-md-5">
                <b-dropdown size="sm" id="dropdown-1" right>
                  <template slot="button-content">
                    <i style="font-size: 1rem" class="fas fa-cog"></i>
                    <span class="font-weight-bolder">Thao tác</span>
                  </template>
                  <b-dropdown-item @click="excelModal">
                    <span>
                      <i style="font-size: 1rem" class="far fa-file-excel"></i>
                      &nbsp; Xuất Excel</span
                    >
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </template>
          <template v-slot:preview>
            <b-row class="mb-5">
              <b-col md="3">
                <multiselect
                  v-model="valueStore"
                  :options="optionsStore"
                  :multiple="true"
                  track-by="code"
                  :close-on-select="false"
                  @select="onSelectStore($event)"
                  @remove="onRemoveStore($event)"
                  :show-labels="false"
                  :showNoResults="false"
                  :showPointer="false"
                  :custom-label="customLabelStore"
                  placeholder="Chọn cửa hàng"
                >
                  <template slot="tag">{{ '' }}</template>
                  <template slot="selection" slot-scope="{ values, isOpen }"
                    ><span
                      class="multiselect__single"
                      v-if="values.length &amp;&amp; !isOpen"
                      >{{ valueStore.length }} cửa hàng đã chọn</span
                    ></template
                  >
                  <template v-slot:no-result>
                    Danh sách cửa hàng rỗng!
                  </template>
                  <span
                    class="checkbox-label"
                    slot="option"
                    slot-scope="scope"
                    @click.self="select(scope.option)"
                  >
                    {{ scope.option.name }}
                    <input
                      class="test"
                      type="checkbox"
                      v-model="scope.option.checked"
                      @focus.prevent
                    />
                  </span>
                  <span
                    class="checkbox-label"
                    style="font-size: 12px"
                    slot="noResult"
                  >
                    Không có kết quả
                  </span>
                </multiselect>
              </b-col>
              <b-col md="2">
                <date-picker
                  placeholder="Từ ngày"
                  class="form-control-sm"
                  :config="dpConfigs.date"
                  v-model="dateFrom"
                ></date-picker>
              </b-col>
              <b-col md="2">
                <date-picker
                  placeholder="Tới ngày"
                  class="form-control-sm"
                  :config="dpConfigs.date"
                  v-model="dateTo"
                ></date-picker>
              </b-col>
              <b-col md="2">
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="onFilter"
                  >Lọc</b-button
                >
              </b-col>
            </b-row>

            <b-row>
              <b-col md="3">
                <p class="text-title text-lable-total">
                  Phục vụ thành công: {{ formatMoney(billCount)
                  }}{{ successPerc }}
                </p>
              </b-col>
              <b-col md="3">
                <p class="text-title text-lable-total">
                  Không phục vụ được: {{ formatMoney(orderCantServeCount)
                  }}{{ failedPerc }}
                </p>
              </b-col>
              <b-col md="3">
                <p class="text-title text-lable-total">
                  Tổng lượt ghé thăm: {{ formatMoney(total) }}
                </p>
              </b-col>
            </b-row>

            <table
              class="
                table table-bordered table-vertical-center table-hover
                col-md-8
              "
            >
              <thead>
                <tr>
                  <th style="textalign: center; width: 40px">STT</th>
                  <th scope="col" style="width: 30%">Cửa hàng</th>
                  <th
                    scope="col"
                    style="width: 20%"
                    class="textWidth text-right"
                  >
                    Phục vụ thành công
                  </th>
                  <th
                    scope="col"
                    style="width: 20%"
                    class="textWidth text-right"
                  >
                    Không phục vụ được
                  </th>
                  <th
                    scope="col"
                    style="width: calc(30%-40px)"
                    class="textWidth text-right"
                  >
                    Số lượt ghé thăm
                  </th>
                </tr>
              </thead>

              <tbody v-for="(item, index) in desserts" :key="index">
                <tr class="heighRow">
                  <td>
                    <div class="d-flex justify-content-center">
                      <span v-text="item.stt" class="text-right"></span>
                    </div>
                  </td>
                  <td class="textWidth">
                    <span>{{ item.name }}</span>
                  </td>
                  <td class="textWidth text-right">
                    <span>{{ item.billCount }}</span>
                  </td>
                  <td class="textWidth text-right">
                    <span>{{ item.orderCantServeCount }}</span>
                  </td>
                  <td class="textWidth text-right">
                    <span>{{ item.total }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <b-row class="col-md-8" style="margin: 0px; padding: 0px">
              <b-col style="margin: 0px; padding: 0px">
                <p class="mt-3 text-dark" style="padding: 0; font-weight: 500">
                  Tổng số: {{ totalItem }}
                </p>
              </b-col>
              <b-col style="margin: 0px; padding: 0px">
                <b-pagination-nav
                  class="custom-pagination"
                  v-if="numberOfPage >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="numberOfPage"
                  use-router
                  @change="fetchStatistical"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                  prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                  last-class="page-item-last btn btn-icon btn-sm my-1 "
                  page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
          </template>
        </KTCodePreview>
        <b-modal
          ref="excel-modal"
          hide-footer
          title="Xuất excel"
          id="excel-modal"
        >
          <v-form ref="form" lazy-validation>
            <div class="mb-4">
              <div class="row">
                <div class="col-6">
                  <b-form-group label="Chọn cột cần xuất:">
                    <b-form-radio-group
                      id="radio-group-excel-1"
                      v-model="selectedRowExcel"
                      name="radio-sub-component-1"
                      @change.native="showHeader"
                    >
                      <b-form-radio value="all">Tất cả</b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                  <b-form-group label="Danh sách cần xuất:">
                    <b-form-radio-group
                      id="radio-group-excel-2"
                      v-model="selectedListExcel"
                      name="radio-sub-component-2"
                    >
                      <b-form-radio value="all">Tất cả các trang</b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </div>
              </div>
            </div>

            <b-button
              style="width: 110px"
              variant="primary"
              size="sm"
              @click="onExportExcel"
              class="mr-3"
            >
              <v-icon small class="text-white" v-b-tooltip>mdi-download</v-icon>
              <strong>Xuất Excel</strong>
            </b-button>

            <b-button
              style="width: 100px"
              variant="secondary"
              size="sm"
              @click="$bvModal.hide('excel-modal')"
            >
              <strong>Hủy</strong>
            </b-button>
          </v-form>
        </b-modal>
      </div>
    </div>
  </div>
</template>



<script>
import Multiselect from 'vue-multiselect';
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import JwtService from '@/core/services/jwt.service';
import Loader from '@/view/content/Loader.vue';
import moment from 'moment';
import ApiService from '@/core/services/api.service';
import datePicker from 'vue-bootstrap-datetimepicker';
import { createFile, getFileName } from '../../../utils/file-download';
import { BASE_URL } from '../../../utils/constants';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import jQuery from 'jquery';
import axios from 'axios';
import { makeToastFaile } from '../../../utils/common';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});

export default {
  data() {
    return {
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        start: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        end: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      dateFrom: moment().subtract(15, 'days').format('DD/MM/YYYY'),
      dateTo: moment().format('DD/MM/YYYY'),
      valueStore: [],
      optionsStore: [],
      total: 0,
      billCount: 0,
      orderCantServeCount: 0,
      successPerc: '',
      failedPerc: '',
      filteredOptions: [],
      desserts: [],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },

      page: 1,
      pageSize: 30,
      numberOfPage: 1,
      totalItem: 0,
      selectedRowExcel: 'all',
      selectedListExcel: 'all',
    };
  },
  components: {
    KTCodePreview,
    Loader,
    Multiselect,
    datePicker,
  },
  created() {
    this.fetchStore();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Thống kê lượt phục vụ', route: '/order-cant-serve' },
    ]);
    this.getFillter();
    this.page = this.$route.query.page;
    if (!this.page) {
      this.page = 1;
    }
  },
  methods: {
    formatMoney: function (number) {
      if (!number) return 0;
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    customLabelStore(option) {
      return `${option.name}`;
    },
    onSelectStore(option) {
      const index = this.optionsStore.findIndex(
        (item) => item.name == option.name
      );
      this.optionsStore[index].checked = true;
    },
    onRemoveStore(option) {
      const index = this.optionsStore.findIndex(
        (item) => item.name == option.name
      );
      this.optionsStore[index].checked = false;
    },
    fetchStore: async function () {
      this.optionsStore = [];
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        this.optionsStore = stores.map((element) => {
          return {
            code: element.id,
            name: element.shortName,
            checked: false,
          };
        });
        this.getFillter();
        this.fetchStatistical();
      });
    },
    onFilter() {
      this.$route.query.page = 1;
      this.changeURL();
      this.fetchStatistical();
    },
    setFillter() {
      const queryObj = {};

      // parse list store id
      const listStoreId = (this.optionsStore || [])
        .filter((element) => element.checked)
        .map((element) => element.code);
      if (listStoreId.length > 0) {
        queryObj.listStore = listStoreId;
      }

      return queryObj;
    },
    changeURL() {
      const queryObj = this.setFillter();
      this.$router.push({
        name: 'customer-serve',
        query: queryObj,
      });
    },
    getFillter() {
      this.listStoreId = this.$route.query.listStore || null;
      if (Array.isArray(this.listStoreId)) {
        this.listStoreId.forEach((id) => {
          const index = this.optionsStore.findIndex((item) => item.code == id);
          if (index > -1) {
            this.optionsStore[index].checked = true;
            this.valueStore.push(this.optionsStore[index]);
          }
        });
      } else if (this.listStoreId) {
        const index = this.optionsStore.findIndex(
          (item) => item.code == this.listStoreId
        );
        if (index > -1) {
          this.optionsStore[index].checked = true;
          this.valueStore.push(this.optionsStore[index]);
        }
      }
    },
    excelModal: async function () {
      this.$refs['excel-modal'].show();
    },
    onExportExcel: function () {
      const listStoreId = this.optionsStore
        .filter((element) => element.checked)
        .map((element) => element.code);

      const params = {
        storeIds: listStoreId.length ? listStoreId : null,
        fromDate: this.dateFrom
          ? moment(this.dateFrom, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        toDate: this.dateTo
          ? moment(this.dateTo, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
      };

      axios({
        params,
        url: `${BASE_URL}customer/export-customer-serve`,
        method: 'post',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${JwtService.getToken()}`,
        },
      })
        .then((response) => {
          const fileName = getFileName(response);
          createFile(response.data, fileName);
        })
        .catch((err) => {
          console.log('exportExcel error', err);
        });
    },
    fetchStatistical() {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }

      const listStoreId = this.optionsStore
        .filter((element) => element.checked)
        .map((element) => element.code);

      const params = {
        size: this.pageSize,
        page: this.page,
        storeIds: listStoreId.length ? listStoreId : null,
        fromDate: this.dateFrom
          ? moment(this.dateFrom, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        toDate: this.dateTo
          ? moment(this.dateTo, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
      };

      ApiService.query('/customer/statisticize', { params }).then(
        ({ data }) => {
          if (data.status === 0) {
            return makeToastFaile(data.message);
          }
          this.numberOfPage = data.data.total_page;
          this.totalItem = data.data.total_row;

          this.total = 0;
          this.billCount = 0;
          this.orderCantServeCount = 0;
          this.successPerc = '';
          this.failedPerc = '';

          this.desserts = data.data.data.map((item, index) => {
            item.stt = index + 1 + (this.page - 1) * this.pageSize;
            item.total =
              (item.billCount || 0) + (item.orderCantServeCount || 0);
            this.total = this.total + item.total;
            this.billCount = this.billCount + item.billCount;
            this.orderCantServeCount =
              this.orderCantServeCount + item.orderCantServeCount;
            const successPerc = item.total
              ? ((item.billCount / item.total) * 100).toFixed(1)
              : 100;
            const failedPerc = (100 - successPerc).toFixed(1);
            item.billCount = `${this.formatMoney(
              item.billCount
            )}(${successPerc}%)`;
            item.orderCantServeCount = `${this.formatMoney(
              item.orderCantServeCount
            )}(${failedPerc}%)`;
            item.total = this.formatMoney(item.total);
            return item;
          });

          const successPerc = this.total
            ? ((this.billCount / this.total) * 100).toFixed(1)
            : 100;
          this.successPerc = `(${successPerc}%)`;
          this.failedPerc = `(${(100 - successPerc).toFixed(1)}%)`;
        }
      );
    },
  },
};
</script>


<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.icon:hover {
  background-color: #90c6fc;
}

.multiselect {
  min-height: initial;
}
.multiselect__tags {
  font-size: 12px !important;
  display: block;
  padding: 3px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  min-height: initial;
}
.multiselect__element {
  min-height: initial;
  display: block;
  background: #fff;
  font-size: 12px;
}
.multiselect__input {
  min-height: initial;
  display: block;
  background: #fff;
  font-size: 12px;
}
.multiselect__single {
  font-size: 12px;
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5eaee;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

.test {
  position: absolute;
  right: 1vw;
}
.checkbox-label {
  display: block;
}

.text-lable-total {
  font-weight: 600;
}
</style>